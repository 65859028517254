@font-face { font-family: 'Blotus';
  src: local('Blotus'),
       url('../fonts/BLotus.woff') format('woff');
}
.navbar {
  width: 97%;
  height: 10vh;
padding: 6em;
  background: #1b1919;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../images/bg/missile2.png');
  background-repeat: no-repeat;
}

.navbar-logo {
  color: white;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items:first baseline;
  justify-content: space-between;
  width: 10rem;
}

.list {
  display: flex;
  list-style: none;
  text-align: center;
  height: 97%;
  width: 70vw;
  justify-content: center;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 10vh;
}

.nav-item a {
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  margin-right: 2rem;
  padding: 0.375em 1em;
  border-radius: 0.3125em;
}

.nav-item a:hover {
  background: rgb(0, 212, 212);
}
.message {
  position: relative;
  margin-top: 2em;
  color: white;
  font-family: 'BLotus';
  direction: rtl;
}
@media screen and (max-width: 768px){
  .navbar {
    height: 30%;
  padding: 1.3em;
  display: flex;
  float: right;
  flex-direction: column;
    background: #1b1919;
    justify-content: center;
    align-items: center;
    background-image: url('../images/bg/missile2.png');
    background-repeat: no-repeat;
     }
  .list {
      flex-direction:column;
      height: 80%;
      
  }
  .nav-item:nth-child(1){
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      margin-top: 0.3em;
  }
  .nav-item {
      width: 100%;
      z-index: auto;
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      text-align: center;
      margin-right: 0px;
      padding: 0.2em 0;
  }
  .btn {
      /* display: block; */
      font-size: 0.7em;
      float: right;
      margin-top: 7%;
  }
  .header {
      float: none;
      display: block;
      text-align: left;
    }
    .header-right {
      float: none;
    }

}
@media screen and (orientation : landscape) {
  .navbar {
    max-width:99% ;
    height: 40%;
  padding: 4em;
  display: flex;
  float: right;
  flex-direction: row;
    background: #1b1919;
    justify-content: center;
    align-items: center;
    background-image: url('../images/bg/missile2.png');
    background-repeat: no-repeat;
     }
  .list {
      flex-direction:column;
      height: 95%;
      
  }
  .nav-item:nth-child(1){
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      margin-top: 0.6em;
  }
  .nav-item {
      width: 95%;
      z-index: auto;
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      text-align: center;
      margin-right: 0px;
      padding: 1.8em 1.7em;
  }
  .btn {
      /* display: block; */
      font-size: 0.7em;
      float: right;
      margin-top: 8%;
  }
 
}