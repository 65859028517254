.services-submenu {
  width: 10rem;
  position: absolute;
  top: 67px;
  list-style: none;
  text-align: start;
}

.services-submenu li {
  background: rgb(0, 212, 212);
  cursor: pointer;
}

.services-submenu li a:hover {
  background: rgb(0, 175, 175);
}

.services-submenu.clicked {
  display: none;
}

.submenu-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 1em;
}
