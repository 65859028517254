@import url('http://fonts.googleapis.com/earlyaccess/scheherazade.css'); 
@import url('https://fonts.cdnfonts.com/css/persian');
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap");

 @font-face { font-family: 'Blotus';
    src: local('Blotus'),
         url('./fonts/BLotus.woff') format('woff');
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;  
}
.home,
.consulting,
.contactus,
.design,
.development,
.marketing,
.products,
.services,
.signup { 
  justify-content: center;
  align-items: center;
  height: 190vh;
  width: 92%;
  font-size: 4rem;
  font-family: 'BLotus', 'persian' , "PT Sans", 'scheherazade';
  font-weight: bolder;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  float: right;
  z-index: 1;
  /* text-shadow: 0.25em 0.25em 0.25em #fff; */
}
.home {
  background-image:  url(./images//bg/DontStopInvesting.jpg),url(./images/bg/sEthereumDark.jpg);
  background-size:85vw,auto;
   width: 122%,99%;
  margin-top: 17vh,0vh;
  height: 98%;
  background-position: left top,left bottom;
  background-repeat: no-repeat,repeat;
  padding: 15px;
}
.hi {
  float :right;
  color:chartreuse;
  margin-right: 0.5em;
}
.buySubscribe {
  position: relative;
  margin-top: 100em;
  margin-left:0.6em;
  background-color: #010216;
  border: 0.6em;
  width: 80%;
  color: white;
  align-self: center;
  text-decoration: none;
  cursor: pointer;
  font-family:'BLotus';
  font-size: 1.5em;
  border-radius: 1em;
  box-shadow: 0 9px #999;
  z-index: 10;
}
.titr {
 position: relative;
 width:100%;
 margin-top: -1em;
}

@media (min-width : 800px) or (min-width: 1200px){
  .buySubscribe {
  position: relative;
  margin-top: 200em;
  margin-left:0.6em;
  background-color: #010216;
  border: 0.6em;
  width: 80%;
  color: white;
  align-self: center;
  text-decoration: none;
  cursor: pointer;
  font-family:'BLotus';
  font-size: 1.5em;
  border-radius: 1em;
  box-shadow: 0 9px #999;
  z-index: 10;
}
.titr {
 position: relative;
 width:100%;
 margin-top: -1em;
}

}

.consulting {
  background-image: url(./images/bg/cryptocurrencyGold.webp);
}

.design {
  background-image: url(./images/bg/shutterstock.jpg);
}

.development {
  background-image: url(./images/bg/finance-bitcoin.jpeg);
}

.marketing {
  background-image: url(./images/bg/bitcoin-mining2.jpg);
}

.products {
  background-image: url(./images/bg/bitcoin.jpg);
}

.contactus {
  background-image: url(./images/bg/coinsharesbg.jpg);
}

.services {
  background-image: url(./images/bg/BitCurrency.PNG);
}

.signup {
    background-image: url(./images/bg/Bgbit.png);
      }

    .signup input {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      width: 95%;
      padding: 1.5em;
      margin: 0.2em;
      border: 2px solid red;
  border-radius: 4px;
    }
    .signup input:focus {
      width: 100%;
      background-color: lightblue;
    }
    .signup button {
      background-color: #023f29;
      border: 0.6em;
      width: 80%;
      color: white;
      align-self: center;
      padding: 1em 2em;
      text-decoration: none;
      margin-left: 3em;
      margin-right: 1em;
      cursor: pointer;
      font-size: x-large;
      border-radius: 15px;
  box-shadow: 0 9px #999;
    }
.loginLink {
  font-size:xx-large;
  color: rgb(156, 253, 110);
  text-decoration: none;
  text-shadow: black;
  align-items: normal;
  background-color: rgba(0,0,0,0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;
  width: auto;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
}

/* Mozilla uses a pseudo-element to show focus on buttons, */
/* but anchors are highlighted via the focus pseudo-class. */

@supports (-moz-appearance:none) { /* Mozilla-only */
  button::-moz-focus-inner { /* reset any predefined properties */ 
    border: none;
    padding: 0;
  }
  button:focus { /* add outline to focus pseudo-class */
    outline-style: dotted;
    outline-width: 1px;
  }
 }

.login {
  background-image: url("./images/bg/trading.jpg");
}
.login input {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: 95%;
  padding: 1.5em;
  margin: 0.4em;
  border: 2px solid red;
border-radius: 4px;
color:white;
background-color: black;
}
.login input:focus {
  width: 100%;
  background-color: lightblue;
}
.login button {
  background-color: #010216;
  border: 0.6em;
  width: 80%;
  color: white;
  align-self: center;
  padding: 1em 2em;
  text-decoration: none;
  margin-left: 3em;
  margin-right: 1em;
  cursor: pointer;
  font-size: x-large;
  border-radius: 1em;
  box-shadow: 0 9px #999;
}
.signLink {
font-size:xx-large;
color: white;
text-decoration: none;
text-shadow: black;
.button:hover {background-color: #3e8e41}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
} 
.head {
  height: 196vh;
  
  z-index: 2;
  float: left;
}
@media screen and (max-width: 768px)
and (orientation :portrait) {
  .titr {
    position: relative;
    width:113%;
    height: 135%;
    margin-top: -0.4em;
   }
   .buySubscribe {
    position: relative;
    margin-top: 9.6em;
    margin-left:0.2em;
    background-color: #010216;
    border: 0.6em;
    width: 95%;
    color: white;
    align-self: center;
    text-decoration: none;
    cursor: pointer;
    font-family:'BLotus';
    font-size: 0.6em;
    border-radius: 1em;
    box-shadow: 0 9px #999;
    z-index: 10;
  }
  
}
/* Tablet (landscape) ----------- */
@media screen and (orientation : landscape) {
  .home {
    background-image:  url(./images//bg/DontStopInvesting.jpg),url(./images/bg/EthereumBlackTiny.jpg);
    background-size:95%,auto;
    width: 112%,60%;
    margin-top: 17vh,8vh;
    height: 58%;
    background-position: left top,left bottom;
    background-repeat: no-repeat,repeat;
    
  }
  .titr {
    position: relative;
    width:113%;
    height: 140%;
    margin-top: -0.55em;
   }
   .buySubscribe {
    position: relative;
    margin-top: 21.5em;
    margin-left:0.3em;
    background-color: #010216;
    border: 0.4em;
    width: 97%;
    color: white;
    align-self: center;
    text-decoration: none;
    cursor: pointer;
    font-family:'BLotus';
    font-size: 0.5em;
    border-radius: 1em;
    box-shadow: 0 9px #999;
    z-index: 10;
  }
}
.stock {
  position: relative;
  z-index: 15;
}