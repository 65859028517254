.social {
    position: fixed;
    
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:last baseline;
    margin-top: 5em;
    z-index: 99;
}
.social li{
    color:#fff;
    background: rgba(0,0,0,0.4);
    font-size: 0.6em;
    font-weight: 600;
    text-decoration: none;
    display: block;
    
    margin: 0.8em;
    padding: 0.8em;
    width: 4.7em;
    height: 4.7em;
    text-align:left;
    border-radius: 50px;
    transition: 0.4s;
    transition-property: transform;
    }
   
    .social insta {
      background-color: orange;
    }
    .social li:hover{
  transform: translate(4.5em,0);
    }