.btn {
  border: none;
  background: transparent;
  padding: 0.375em 1em;
  margin: 0.4em;
  font-size: 1.1rem;
  color: white;
  background: rgb(0, 212, 212);
  border-radius: 0.36em;
  transition: 0.3s all ease-out;
  cursor: pointer;
}

.btn:hover {
  color: rgb(0, 212, 212);
  background: white;
}
@media screen and (max-width:584px){
  .btn {
    float: right;
    margin-top: 7%;
  }
}
@media screen and (orientation : landscape) {  
  .btn {
    display:flex;
    padding: 0.275em 0.8em;
    margin: 0.4em;
    font-size: 1rem;   
  float: right;
  margin-top: 7%;
}}

